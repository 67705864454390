import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { Section, Container } from "../global"
import appstore from "../../images/appstore.png"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { loadStripe } from "@stripe/stripe-js"
import google from "../../images/google.png"
import Img from "gatsby-image"

import firebase from "gatsby-plugin-firebase"
import { AuthContext } from "../../context/auth"
import { navigate } from "gatsby"
import logo from "../../images/day.png"

const Dashboard = () => {
  const { user } = useContext(AuthContext)
  const [currentSubscription, setCurrentSubscription] = useState(false)
  const [cheersToGive, setCheersToGive] = useState([])
  const [loading, setLoading] = useState(true)
  const [btcPoints, setBtcPoints] = useState(0)
  const [userAvatar, setUserAvatar] = useState(
    "https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Floading.gif?alt=media&token=a3cbd288-ea7e-48af-a691-d52a07fd7ff9"
  )
  const [displayUsername, setDisplayUsername] = useState("")

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("signed out")
        //window.location.href = "/dashboard"
        // ...
        navigate("/")
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
      })
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection("users")
          .where("uid", "==", user.uid)
          .get()
          .then((querySnapshot) => {
            console.log("querySnapshot =>", querySnapshot)
            firebase
              .firestore()
              .collection("cheers")
              .where("createdBy", "==", user.uid)
              .get()
              .then((querySnapshot) => {
                console.log("querySnapshot", querySnapshot.docs.length)

                var localCheersToGive = []
                firebase
                  .firestore()
                  .collection("cheers")
                  .where("ownerId", "==", user.uid)
                  .where("expired", "==", false)
                  .get()
                  .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      // doc.data() is never undefined for query doc snapshots
                      localCheersToGive.push(doc.id)
                    })
                    console.log("cheers to give", localCheersToGive.length)
                    setCheersToGive(localCheersToGive)
                  })
                  .catch((error) => {
                    console.log("Error getting documents: ", error)
                  })
              })
              .catch((error) => {
                console.log("Error getting documents: ", error)
              })

            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              console.log("user data => ", doc.data())
              setUserAvatar(doc.data().avatarURL)
              setDisplayUsername(doc.data().displayUsername)
              setBtcPoints(doc.data().btcPoints)
            })
          })
          .catch((error) => {
            console.log("Error getting documents: ", error)
          })

        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .collection("subscriptions")
          .where("status", "in", ["trialing", "active"])
          .onSnapshot(async (snapshot) => {
            // In this implementation we only expect one active or trialing subscription to exist.
            const doc = snapshot.docs[0]
            if (typeof doc !== "undefined") {
              setCurrentSubscription(true)
            }
          })
      } else {
        console.log("no user")
      }
      setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <HeaderWrapper id="top">
        <div>
          <LoadingWrapper>
            <img src={logo} />
          </LoadingWrapper>
        </div>
      </HeaderWrapper>
    )
  } else if (user) {
    return (
      <HeaderWrapper id="top">
        <StyledContainer>
          <Subtitle>
            Greetings, {displayUsername} so lovely to see you today.
            <div style={{ paddingTop: "7px" }}>
              {btcPoints.toLocaleString("en-US")} BTC points
            </div>
          </Subtitle>
          <ImageWrapper>
            <div className="profileImage">
              <img src={userAvatar} alt="Avatar" />
            </div>
            <FeatureTitle>
              <button
                onClick={() => {
                  navigate(`/members/${displayUsername}`)
                }}
                style={{ color: "black", background: "none" }}
              >
                <img
                  style={{
                    maxHeight: "30px",
                    width: "auto",
                    position: "relative",
                    top: "5px",
                    marginRight: "3px",
                  }}
                  alt="Feature"
                  src="https://firebasestorage.googleapis.com/v0/b/perfect-day-19585.appspot.com/o/website%2Ficons%2Fintention.png?alt=media&token=6446f53a-ffe0-4e4b-a83d-1f59c185ce5e"
                />
                View Profile
              </button>
            </FeatureTitle>
          </ImageWrapper>
          <GetStartedContainer>
            
            <div style={{ flexDirection: "row" }}>
              <a href="https://apps.apple.com/us/app/day-the-dailyness-app/id1555873146 ">
                <img
                  src={appstore}
                  style={{
                    paddingRight: "10px",
                    float: "left",
                    paddingTop: "10px",
                    width: "200px",
                  }}
                  alt="AppStore Logo"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.perfectdayreactnative">
                <img
                  src={google}
                  style={{ paddingTop: "10px", width: "200px", height: "67px" }}
                  alt="PlayStore logo"
                />
              </a>
            </div>
          </GetStartedContainer>

          <GetStartedContainer>
            <FeatureTitle>
              <button
                onClick={() => {
                  signOut()
                }}
                style={{ color: "black", background: "none" }}
              >
                Log out
              </button>
            </FeatureTitle>
          </GetStartedContainer>
        </StyledContainer>
      </HeaderWrapper>
    )
  } else {
    return (
      <HeaderWrapper id="top">
        <Container>
          <Flex>
            <HeaderTextGroup>
              <h1>You are out =(</h1>
              <h1>
                <div style={{ fontSize: "30px" }}>
                  A fun & friendly monthly competition
                </div>
              </h1>
              <h2>
                Please{" "}
                <a href="/signin" alt="signin">
                  sign in
                </a>{" "}
                to view your dashboard.
              </h2>
              <img src={appstore} alt="AppStore Logo" width="250px" />
            </HeaderTextGroup>
          </Flex>
        </Container>
      </HeaderWrapper>
    )
  }
}

export default Dashboard

const HeaderWrapper = styled.header`
  background-color: #ffffff;
  padding: 160px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const LoadingWrapper = styled.div`
  top: 50%;
  left: 50%;
  margin: -50px auto auto -100px; /*Half of image width, 0, 0, half of image height*/
  text-align: center;
  opacity: 0.3;
`

const HeaderTextGroup = styled.div`
  margin: 0;
  > div {
    width: 120%;
    margin-bottom: -4.5%;
    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 16px;
    }
  }
  h1 {
    margin: 0;
    color: ${(props) => props.theme.color.primary};
  }
  h2 {
    margin-bottom: 24px;
    margin-top: 24px;
    ${(props) => props.theme.font_size.regular}
  }
  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`
const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 870px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureText = styled.p`
  text-align: center;
`
const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`
