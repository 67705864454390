import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Dashboard from "../components/sections/dashboard"
import Footer from "../components/sections/footer"

const DashboardPage = () => (
  <Layout>
    <SEO title="Dashboard" />
    <Navigation />
    <Dashboard />
    <Footer />
  </Layout>
)

export default DashboardPage
